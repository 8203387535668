<template>
    <div id="courseTeaching">
        <div class="box">
            <div style="width: 100%;height: 60px;display: flex;justify-content: space-between;margin-top: 10px;">
            <div>
                <div v-for="(item,index) in daohangData" :key="index"  :class="indexYs == index?'whiteData':'default'" @click="daohangClick(index)">
                    {{ item.name }}
                </div>
            </div>
            <div>
                <!-- <el-button type="primary" round style="background-color: #76C8F6;border: none;" >编辑课程</el-button> -->
                <!-- <el-button type="primary" round style="background-color: #76C8F6;border: none;">开始上课</el-button> -->
            </div>
          </div>
          <div class="boxCneterData" >
            <p style="padding-top: 20px;">
                <!-- <span style="font-size: 16px;font-weight: 600;color: #76C8F6;;padding-left: 20px;">|</span> -->
                <span style="font-weight: 800;font-size: 20px;cursor: pointer;" class="el-icon-arrow-left" @click="blackClick"></span><span style="padding-left: 10px;font-weight: 600;">作业成绩</span>
            </p>
            
            <p style="padding: 20px;font-size: 14px;">
                <span>章名称：{{ courseChapterName }}</span>
                <span style="padding-left: 20px;">节名称：{{ courseSectionName }}</span>
                <!-- <span style="padding-left: 20px;">习题名称：第一章 第一节习题</span> -->
            </p>
            <p  style="padding-left: 20px;font-size: 14px;">
                <el-input style="width: 200px;" v-model="studenName" placeholder="学生姓名" size="mini" />
                <el-select v-model="isSubmit" class="m-2" placeholder="是否提交" size="mini" style="margin-left: 20px;">
                    <el-option
                    v-for="item in options"
                    :key="item.isSubmit"
                    :label="item.label"
                    :value="item.isSubmit"
                    />
                </el-select>
                <!-- <el-select v-model="value" class="m-2" placeholder="是否通过" size="mini" style="margin-left: 20px;">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    />
                </el-select> -->
                <el-button type="primary" style="background-color: #76C8F6;border: none;margin-left: 20px;" size="mini" @click="Searching()">搜索</el-button>
                <!-- <el-button type="primary" style="background-color: #76C8F6;border: none;margin-left: 20px;float: right;margin-right: 20px;" size="mini" >重新发放</el-button> -->
                <!-- <el-button type="primary" style="background-color: #76C8F6;border: none;margin-left: 20px;float: right;" size="mini" > 撤销发放</el-button> -->
            </p>
            <el-table :data="tableData" stripe style="width: 100%;height:500px" max-height="500px">
            <el-table-column type="index" width="80" label="序号" align="center"/>
            <el-table-column prop="realName" label="学生姓名" align="center"/>
            <el-table-column label="作业名称" align="center">
                <div>
                    {{ exercisesnName }}
                </div>
            </el-table-column>
            <el-table-column prop="isSubmit" label="是否提交" align="center">
                <template #default="{row}">
                    <div>
                        {{row.isSubmit == true ? '是' : '否' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="insertTime" label="提交时间" align="center">
                <template #default="{row}">
                    <div>
                        {{row.insertTime == '' ? '--' : row.insertTime }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="correctCount" label="正确数" align="center"/>
            <el-table-column prop="allCount" label="试题数" align="center"/>
            <el-table-column prop="accuracy" label="正确率" align="center">
                <template #default="{row}">
                    <div>
                        {{`${parseInt(row.accuracy)}%`}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template #default="{row}">
                    <el-button @click="handleClick(row)" type="text" size="small">试卷预览</el-button>
                </template>
            </el-table-column>
          </el-table>
          <div style="display: flex;justify-content: center;width: 100%;height: 40px;align-items: center;">
            <el-pagination v-if="total>5" style="margin: 0 auto;text-align: center;" :page-size="pageSize" :current-page="pageIndex" layout="prev, pager, next" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>
        </div>
        </div>

        <el-dialog
        v-model="PreviewDialog"
        title="试卷预览"
        width="60%"
        align-center
      >
        <div
            style=" width: 100%;height: 30px;background: #fff;border-top: 0.5px solid #edeff2;line-height: 30px;display: flex;align-items: center;">
            <!-- <div style="font-size:14px;margin:0 0 0 20px;">
                <span style="color:#666;">学生姓名：</span>
                <span style="color:#333;">{{ rowname }}</span>
            </div> -->
        </div>
        <div style="width: 100%;height: 700px;overflow-y: auto;">
                <!--  -->
                <p style="font-size: 16px;
                    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                    font-weight: 400;
                    color: #333333;" v-if="Fillintheblanks.length != 0">一、填空题</p>
                <div v-if="Fillintheblanks.length != 0">
                    <div style="background-color: #fff;margin-top: 10px;padding: 10px;"
                        v-for="(item, index) in Fillintheblanks" :key="index">
                        <p style="font-size: 14px;">
                            <span
                                style="background:#76C8F6;color: #fff;display:inline-block;width: 2%;text-align: center;border-radius: 3px;">{{ index+=1 }}</span>
                            <span style="padding-left: 10px;color: 14px;">{{ item.testQuestionContent }}</span>
                            <!-- 是否正确  0、不做统计  1、正确  2、错误  3、空白 :style="item.isCorrect == 2 ? 'color:#F50000;' : 'color:#76C8F6;'"-->
                            <span >
                                <span style="margin:0px 20px;" :style="item.isCorrect == 1 ? 'color:#76C8F6;' : item.isCorrect == 2 ? 'color:#F50000;' : item.isCorrect == 3 ? 'color:#F50000' : 'color:#F50000'">
                                    <span>学生答案：</span>
                                    <span>{{item.questionAnswerContent}}</span>
                                </span>
                                <span style="margin:0px 20px;color:#76C8F6;">
                                    <span>正确答案：</span>
                                    <span>{{answer(item.answers)}}</span>
                                </span>
                            </span>
                            
                        </p>
                        <el-input placeholder="" size="mini" style="margin-top: 10px;"
                            v-for="(item2, index2) in item.answers" :key="index2" v-model="item2.dev">
                            <template #prepend>{{ index2+=1 }}</template>
                        </el-input>
                    </div>
                </div>
                <!--  -->
                <p style="font-size: 16px;
                    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                    font-weight: 400;
                    color: #333333;padding-top: 10px;" v-if="SingleSelection.length != 0">二、单选题</p>
                <div v-if="SingleSelection.length != 0">
                    <div style="background-color: #fff;margin-top: 10px;padding: 10px;"
                        v-for="(item, index) in SingleSelection" :key="index">
                        <p style="font-size: 14px;">
                            <span
                                style="background:#76C8F6;color: #fff;display:inline-block;width: 2%;text-align: center;border-radius: 3px;">{{ index+=1 }}</span>
                            <span style="padding-left: 10px;color: 14px;">{{ item.testQuestionContent }}</span>
                             <!-- 是否正确  0、不做统计  1、正确  2、错误  3、空白 :style="item.isCorrect == 2 ? 'color:#F50000;' : 'color:#76C8F6;'"-->
                             <span >
                                <span style="margin:0px 20px;" :style="item.isCorrect == 1 ? 'color:#76C8F6;' : item.isCorrect == 2 ? 'color:#F50000;' : item.isCorrect == 3 ? 'color:#F50000' : 'color:#F50000'">
                                    <span>学生答案：</span>
                                    <span>{{yesanswer2(item.questionAnswer)}}</span>
                                </span>
                                <span style="margin:0px 20px; color:#76C8F6;">
                                    <span>正确答案：</span>
                                    <span>{{answer2(item.answers)}}</span>
                                </span>
                            </span>
                        </p>
                        <el-radio-group v-model="item.dev" style="margin-top: 10px;">
                            <el-radio v-for="(item2, index2) in item.answers" :key="index2" :label="item2.isOrder">
                                {{ item2.isOrder == 1 ? 'A' : item2.isOrder == 2 ? 'B' : item2.isOrder == 3 ? 'C' : item2.isOrder == 4 ? 'D' : '' }}  {{ item2.content }}
                            </el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <!--  -->
                <p style="font-size: 16px;
                    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                    font-weight: 400;
                    color: #333333;padding-top: 10px;" v-if="Multiple.length != 0">三、多选题</p>
                <div v-if="Multiple.length != 0">
                    <div style="background-color: #fff;margin-top: 10px;padding: 10px;" v-for="(item, index) in Multiple"
                        :key="index">
                        <p style="font-size: 14px;">
                            <span
                                style="background:#76C8F6;color: #fff;display:inline-block;width: 2%;text-align: center;border-radius: 3px;">{{ index+=1 }}</span>
                            <span style="padding-left: 10px;color: 14px;">{{ item.testQuestionContent }}</span>
                             <!-- 是否正确  0、不做统计  1、正确  2、错误  3、空白 :style="item.isCorrect == 2 ? 'color:#F50000;' : 'color:#76C8F6;'"-->
                             <span >
                                <span style="margin:0px 20px;" :style="item.isCorrect == 1 ? 'color:#76C8F6;' : item.isCorrect == 2 ? 'color:#F50000;' : item.isCorrect == 3 ? 'color:#F50000' : 'color:#F50000'">
                                    <span>学生答案：</span>
                                    <span>{{yesanswer2(item.questionAnswer)}}</span>
                                </span>
                                <span style="margin:0px 20px; color:#76C8F6;">
                                    <span>正确答案：</span>
                                    <span>{{answer2(item.answers)}}</span>
                                </span>
                            </span>
                        </p>
                        <el-checkbox-group v-model="item.dev">
                            <el-checkbox v-for="(item2, index2) in item.answers" :key="index2" :label="item2.isOrder">
                                {{ item2.isOrder == 1 ? 'A' : item2.isOrder == 2 ? 'B' : item2.isOrder == 3 ? 'C' : item2.isOrder == 4 ? 'D' : '' }}  {{ item2.content }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
                <!--  -->
                <p style="font-size: 16px;
                    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                    font-weight: 400;
                    color: #333333;padding-top: 10px;" v-if="Judgment.length != 0">四、判断题</p>
                <div v-if="Judgment.length != 0">
                    <div style="background-color: #fff;margin-top: 10px;padding: 10px;" v-for="(item, index) in Judgment"
                        :key="index">
                        <p style="font-size: 14px;">
                            <span
                                style="background:#76C8F6;color: #fff;display:inline-block;width: 2%;text-align: center;border-radius: 3px;">{{ index+=1 }}</span>
                            <span style="padding-left: 10px;color: 14px;">{{ item.testQuestionContent }}</span>
                             <!-- 是否正确  0、不做统计  1、正确  2、错误  3、空白 :style="item.isCorrect == 2 ? 'color:#F50000;' : 'color:#76C8F6;'"-->
                             <span>
                                <span style="margin:0px 20px;" :style="item.isCorrect == 1 ? 'color:#76C8F6;' : item.isCorrect == 2 ? 'color:#F50000;' : item.isCorrect == 3 ? 'color:#F50000' : 'color:#F50000'">
                                    <span>学生答案：</span>
                                    <span>{{yesanswer2(item.questionAnswer)}}</span>
                                </span>
                                <span style="margin:0px 20px; color:#76C8F6;">
                                    <span>正确答案：</span>
                                    <span>{{answer2(item.answers)}}</span>
                                </span>
                            </span>
                        </p>
                        <el-radio-group v-model="item.dev" style="margin-top: 10px;">
                            <el-radio v-for="(item2, index2) in item.answers" :key="index2"
                                :label="item2.isOrder">
                                {{ item2.isOrder == 1 ? 'A' : item2.isOrder == 2 ? 'B' : item2.isOrder == 3 ? 'C' : item2.isOrder == 4 ? 'D' : '' }}  {{ item2.content }}
                            </el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
      </el-dialog>
    </div>
</template>


<script>
import { reactive, toRefs, onMounted, onUnmounted, computed,watch ,defineProps} from "vue";
import { useRouter,useRoute } from "vue-router";
import { useStore } from "vuex";
import { getStorage, setStorage } from "@/js/common";
import {backUrl} from "@/js/util"
import * as echarts from 'echarts';
import {f_get_teacherTaskCourseExercises} from '@/js/classManagement'
import {f_get_ShowJobHistory} from '@/js/homeListApi.js'
export default {
    
    setup (props,ctx) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();
        const state = reactive({
            input:'',
            value:'',
            pageSize:5,
            pageIndex:1,
            total:0,
            options:[//0全部 1已提交 2未提交
                {
                    label:'全部',
                    isSubmit:0,
                },
                {
                    label:'已提交',
                    isSubmit:1,
                },
                {
                    label:'未提交',
                    isSubmit:2,
                }
            ],
            isSubmit:0,
            studenName:'',
            formInline:{
                user:'',
                region:''
            },
            tableData:[],
            radio1:'1',
            indexYs:3,
            courseChapterName:route.query.courseChapterName,
            courseSectionName:route.query.courseSectionName,
            daohangData:[
                {
                    name:'数据'
                },{
                    name:'课后作业'
                },{
                    name:'考试'
                },{
                    name:'上课时间'
                },
            ],
            exercisesnName:'',
            PreviewDialog:false,
              SingleSelection:[],
              Multiple:[],
              Fillintheblanks:[],
              Judgment:[],
           
        })


        const methods ={
            answer(answers) {
        let arr = [];
        answers.forEach((res) => {
          if (res.isAnswer == true) {
            arr.push(res.content)
          }
        })
        return arr.join(',')
      },
      yesanswer(questionAnswer) {
        let arr = [];
        questionAnswer.forEach((res) => {
          arr.push(res.content)
        })
        return arr.join(',')
      },
      //正确答案：
      answer2(answers) {
        let arr = [];
        answers.forEach((res) => {
          if (res.isAnswer == true) {
            if (res.isOrder == 1) {
              arr.push("A")
            } else if (res.isOrder == 2) {
              arr.push("B")
            } else if (res.isOrder == 3) {
              arr.push("C")
            } else if (res.isOrder == 4) {
              arr.push("D")
            }
          }
        })
        return arr.join(',')
      },
      //学生答案：
      yesanswer2(questionAnswer) {
        let arr = [];
        questionAnswer.forEach((res) => {
          if (res.isOrder == 1) {
            arr.push("A")
          } else if (res.isOrder == 2) {
            arr.push("B")
          } else if (res.isOrder == 3) {
            arr.push("C")
          } else if (res.isOrder == 4) {
            arr.push("D")
          }
        })
        return arr.join(',')
      },
            handleClick(row) {
                let datas = {
                    examinationPaperId: row.examinationPaperId
                }
                f_get_ShowJobHistory(datas).then((res) => {
                    if (res.data.code == 1) {
                        // console.log(res.data,'石星雨');
                        let arr = [];
                        state.SingleSelection = [];
                        state.Multiple = [];
                        state.Fillintheblanks = [];
                        state.Judgment = [];
                        res.data.data.forEach(item1 => {
                            item1.forEach((item2) => {
                                arr.push(item2)
                            })
                        });
                        arr.forEach(item => {
                            if (item.topicType == 1) {
                                state.SingleSelection.push({
                                    questionAnswer: item.questionAnswer,
                                    isCorrect: item.isCorrect,
                                    answers: item.answers,
                                    isOrder: item.isOrder,
                                    id: item.id,
                                    testQuestionContent: item.testQuestionContent,
                                    testQuestionAnalysis: item.testQuestionAnalysis,
                                    topicType: item.topicType,
                                    dev: ''
                                })
                            } else if (item.topicType == 2) {
                                state.Multiple.push({
                                    questionAnswer: item.questionAnswer,
                                    isCorrect: item.isCorrect,
                                    answers: item.answers,
                                    isOrder: item.isOrder,
                                    id: item.id,
                                    testQuestionContent: item.testQuestionContent,
                                    testQuestionAnalysis: item.testQuestionAnalysis,
                                    topicType: item.topicType,
                                    dev: []
                                })
                            } else if (item.topicType == 3) {
                                state.Fillintheblanks.push({
                                    questionAnswer: item.questionAnswer,
                                    isCorrect: item.isCorrect,
                                    answers: item.answers,
                                    isOrder: item.isOrder,
                                    id: item.id,
                                    testQuestionContent: item.testQuestionContent,
                                    testQuestionAnalysis: item.testQuestionAnalysis,
                                    topicType: item.topicType,
                                    questionAnswerContent: item.questionAnswerContent,
                                    dev: ''
                                })
                            } else if (item.topicType == 4) {
                                state.Judgment.push({
                                    questionAnswer: item.questionAnswer,
                                    isCorrect: item.isCorrect,
                                    answers: item.answers,
                                    isOrder: item.isOrder,
                                    id: item.id,
                                    testQuestionContent: item.testQuestionContent,
                                    testQuestionAnalysis: item.testQuestionAnalysis,
                                    topicType: item.topicType,
                                    dev: ''
                                })
                            }
                        });

                        state.PreviewDialog = true;

                    } else {
                        ElMessage.error(res.data.message)
                    }
                })
            },
            Searching(){
                methods.f_get_teacherTaskCourseExercises();
            },
            // 获取列表数据
            f_get_teacherTaskCourseExercises(){
                let params = {
                    classNumId:getStorage('classDetailData').id,
                    courseSectionId:route.query.courseSectionId,
                    teacherId:getStorage('userId'),
                    name:state.studenName,
                    isSubmit:state.isSubmit,
                    pageIndex:state.pageIndex,
                    pageSize:state.pageSize
                }
                f_get_teacherTaskCourseExercises(params).then(res=>{
                    if (res.data.code == 1) {
                        state.tableData = res.data.data.list;
                        state.total = res.data.data.totalCount.Value;
                        state.exercisesnName = res.data.data.exercisesnName;
                    }
                })
            },
            handleSizeChange(val){
                state.pageSize = val;
                methods.f_get_teacherTaskCourseExercises();
            },
            handleCurrentChange(val){
                state.pageIndex = val;
                methods.f_get_teacherTaskCourseExercises();
            },
            blackClick(){
                // router.push('/home/courseList')
                router.go(-1)
            },
            daohangClick(index){
                console.log(index);
                state.indexYs = index;
                if (state.indexYs == 0) {
                    router.push('/home/classDetails')
                }else if(state.indexYs == 1){
                    router.push('/home/afterClassExercises')
                }else if(state.indexYs == 2){
                    router.push('/home/examinationIndex')
                }else if(state.indexYs == 3){
                    router.push('/home/classTime')
                }

            },
        }
        onMounted(() => {
            methods.f_get_teacherTaskCourseExercises();
        })
        return {
      ...toRefs(state),
      ...methods,
    };
    }
    }

</script>
<style  lang="scss" scoped>
#courseTeaching{
    width: 100%;
            height: 800px;
            background: #F7F8FA;;
            .box{
        width: 66.5%;
        height: 100%;
        margin: 0 auto;
            }
            .whiteData{
            width: 85px;
            height:30px;
            float: left;
            margin: 10px;
            text-align: center;
            line-height: 30px;
            font-size: 14px;
            cursor: pointer;
            background: #fff;
        }
        .default{
            width: 85px;
            height:30px;
            float: left;
            margin: 10px;
            text-align: center;
            line-height: 30px;
            font-size: 14px;
            cursor: pointer;
            background: #EDEDED;
        }
        .boxCneterData{
            width: 100%;
            height: 800px;
            background: #fff;
        }
}

</style>